import { COMPLIANCE_OCC_TAG } from '@/constants';
import { hasEverOptedIn, isMostRecentConsent, isOptedIn } from '@/helpers/consents';
import { httpClient } from '@/transports/http';
import { ComplianceConsent } from '@/types/mainframe';
import { useGetComplianceV1Consents } from '@core/mainframe-react-query';
import { UseQueryOptions } from 'react-query';

type UseGetOccConsentsData = {
  mostRecentConsent?: ComplianceConsent;
  hasEverOptedIn: boolean;
  isOptedIn: boolean;
};

type UseGetOccConsentsProps = Omit<UseQueryOptions<UseGetOccConsentsData>, 'select'>;

export function useGetOccConsents(props: UseGetOccConsentsProps = {}) {
  return useGetComplianceV1Consents<UseGetOccConsentsData>(httpClient, {
    params: {
      query: {
        tags: COMPLIANCE_OCC_TAG,
      },
    },
    options: {
      ...props,
      // @ts-ignore TODO: Remove this manual casting once the type definition is
      // updated correctly in the specification and we fix our types accordinly.
      select(data: { data: ComplianceConsent[] }) {
        const mostRecentConsent = data.data.find(isMostRecentConsent);
        return {
          mostRecentConsent,
          hasEverOptedIn: hasEverOptedIn(data.data),
          isOptedIn: isOptedIn(mostRecentConsent),
        };
      },
    },
  });
}
