import { URL_HMB_TO_THE_ROAD_AHEAD } from '@/constants/app';

export function WindDownTopBlogPostBanner(props: { title: string | React.ReactNode }) {
  return (
    <div className="flex flex-col sm:flex-row items-center gap-3 bg-red-900 w-full p-4">
      <p className="text-white font-normal">{props.title}</p>
      <a
        href={URL_HMB_TO_THE_ROAD_AHEAD}
        className="bg-gray-700 py-2 px-3 text-sm text-center text-white cursor-pointer whitespace-nowrap rounded-md hover:bg-gray-600"
        target="_blank"
        rel="noreferrer"
      >
        November 2023 Update
      </a>
    </div>
  );
}
