import COLORS from '@/styles/colors';
import cx from 'clsx';
import * as React from 'react';
import Select, { Props } from 'react-select';
import { FormFieldError } from '../form-field-error';
import { mergeRefs } from '../helpers/merge-refs';
import { DropdownArrow } from './components/arrow';

export type DropdownProps = {
  label?: string;
  name?: string;
  className?: string;
  error?: string;
  testId?: string;
  isOptional?: boolean;
  inputClassName?: string;
  valueContainerStyles?: {};
  onChange: (state: { value: string | number; label: string }) => void;
} & Props;

export const Dropdown = React.forwardRef<HTMLInputElement | undefined, DropdownProps>(function Dropdown(
  props: DropdownProps,
  ref,
) {
  const { label = '', placeholder = '', className, valueContainerStyles = {}, ...selectProps } = props;

  const selectRef = React.useRef(null);

  function onFocus(e) {
    props.onFocus && props.onFocus(e);
  }

  function onBlur(e) {
    props.onBlur && props.onBlur(e);
  }

  function getCustomStyles(hasError: boolean, hasLabel: boolean) {
    return {
      placeholder: (provided, state) => ({
        ...provided,
        top: 'unset',
        left: 'unset',
        transform: 'unset',
        fontSize: '0.875rem' /* 14px */,
        color: (hasError && COLORS.red800) || (state.isDisabled && COLORS.silverChalice) || COLORS.silverChalice,
      }),
      valueContainer: (provided) => ({
        ...provided,
        paddingLeft: '0.90rem',
        paddingRight: '0.90rem',
        paddingTop: '0.68rem',
        paddingBottom: '0.68rem',
        fontSize: '0.875rem' /* 14px */,
        borderRadius: '0.2rem',
        div: {
          margin: 0,
        },
        ...valueContainerStyles,
      }),
      option: (provided, state) => ({
        ...provided,
        fontSize: '0.875rem',
        cursor: 'pointer',
        color:
          (state.isDisabled && COLORS.white) ||
          (state.isFocused && COLORS.brandTeal) ||
          (state.isSelected && COLORS.emperor),
        backgroundColor: (state.isDisabled && COLORS.silverChalice) || (state.isFocused && COLORS.white),
        ':active': {
          backgroundColor: COLORS.white,
          color: COLORS.brandTeal,
        },
      }),
      control: (provided, state) => ({
        ...provided,
        borderRadius: '0.4rem',
        paddingLeft: '0.25rem',
        borderColor: (hasError && COLORS.red800) || (state.isDisabled && COLORS.silverChalice) || COLORS.emperor,
        backgroundColor: (state.isDisabled && COLORS.white) || COLORS.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        boxShadow: (hasError && `0 0 0 1px ${COLORS.red800}`) || (state.isFocused && `0 0 0 1px ${COLORS.brand_teal}`),
        color: state.isDisabled && COLORS.silverChalice,
        ':hover': {
          cursor: 'pointer',
          borderColor: (hasError && COLORS.red800) || COLORS.brand_teal,
          backgroundColor: COLORS.grey50,
          boxShadow: hasError && `0 0 0 1px ${COLORS.red800}`,
        },
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: '0.4rem',
        borderColor: COLORS.brandTeal,
        borderWidth: '2px',
        fontSize: '0.875rem' /* 14px */,
        zIndex: 50,
        padding: '0.5rem',
      }),
      groupHeading: (provided) => ({
        ...provided,
        fontSize: '0.75rem' /* 12px */,
        marginBottom: '0.75rem' /* 12px */,
        color: COLORS.black1,
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
      }),
      singleValue: (provided) => ({
        ...provided,
        top: 'unset',
        transform: 'unset',
        margin: '0px',
      }),
    };
  }
  const customStyles = getCustomStyles(!!props?.error, !!label);

  return (
    <div className={cx('flex flex-col gap-1', className)}>
      {!!props.label && (
        <div className="flex justify-between items-center">
          <label
            htmlFor={props.name}
            className={cx('font-medium text-xs', !!props?.error ? 'text-red-800' : 'text-gray-800')}
          >
            {label}
          </label>
          {props.isOptional && <span className="text-tiny rounded px-1 bg-gray-200">Optional</span>}
        </div>
      )}
      <div className={cx(props?.className, 'relative')} data-testid={props.testId}>
        <Select
          id={props.id ?? props.name}
          name={props.name}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={mergeRefs(ref, selectRef)}
          styles={customStyles}
          className={cx('relative', props.inputClassName)}
          placeholder={placeholder}
          classNamePrefix="select"
          aria-labelledby={props.name}
          components={{
            DropdownIndicator: DropdownArrow,
          }}
          {...selectProps}
        />
        {!!props?.error && <FormFieldError>{props.error}</FormFieldError>}
      </div>
    </div>
  );
});
