import cx from 'clsx';

type Props = {
  isOpen: boolean;
  className?: string;
};

function Arrow({ isOpen, className }: Props) {
  return (
    <div className={cx(className, 'mr-2')}>
      <svg width="16" height="12">
        <polygon points={isOpen ? '3,9 13,9 8,4' : '3,4 13,4 8,9'} fill="currentColor" />
      </svg>
    </div>
  );
}

export function DropdownArrow(props: {
  selectProps: { menuIsOpen: boolean; error?: string };
  isDisabled: boolean;
  isFocused: boolean;
  hasValue: boolean;
}) {
  return (
    <Arrow
      isOpen={props.selectProps.menuIsOpen}
      className={cx({ 'text-blue-800': props.hasValue || props.isFocused, 'text-red-800': props.selectProps.error })}
    />
  );
}
