import { Plan } from '@/generated/graphql';
import { toAmountInDollarsFromCents } from '@/helpers/currency';
import { getLastFour } from '@/routes/account/helpers';
import { DateTime } from 'luxon';

export function getPlanTransactionDescription(
  isCredit: boolean,
  planTitle: string,
  planAccountNumber: string,
  planBalanceInCents: string | null,
) {
  const mainDescription = `${isCredit ? 'To' : 'From'} Plan: ${planTitle} ${getLastFour(planAccountNumber)}`;
  const balancePart = planBalanceInCents ? ` (${toAmountInDollarsFromCents(planBalanceInCents)})` : '';

  return `${mainDescription}${balancePart}`;
}

export function sortPlansByDate(planA: Plan, planB: Plan) {
  return DateTime.fromISO(planB.createdAt).toMillis() - DateTime.fromISO(planA.createdAt).toMillis();
}
