import { FEATURE_FLAG_DANGER_ZONE_MAINTENANCE } from '@/constants/features';
import { Alert, AlertTitle } from '@mui/material';
import { useContext } from 'react';
import { FlagsContext } from 'react-unleash-flags';

export function FeatureFlagBanner() {
  const feature = useContext(FlagsContext)?.getFlag(FEATURE_FLAG_DANGER_ZONE_MAINTENANCE);
  if (feature?.enabled) {
    return (
      <Alert severity="warning">
        <AlertTitle>Maintenance Notice</AlertTitle>
        <p>{feature.description}</p>
      </Alert>
    );
  }
}
