import { InformationCircleIcon } from '@heroicons/react/24/outline';
import * as React from 'react';

type CloseAccountInfoBannerProps = {
  title: string;
};

export function CloseAccountInfoBanner(props: CloseAccountInfoBannerProps) {
  return (
    <div className="flex cursor-default text-base font-medium items-center gap-1 p-2 bg-purple-50 rounded-lg border-1 border-purple-900 text-purple-900 mb-2">
      <InformationCircleIcon className="w-5 min-w-[20px]" />
      <p className="ml-1 mr-2 font-normal">{props.title}</p>
    </div>
  );
}
