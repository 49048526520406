import { InformationCircleIcon } from '@heroicons/react/24/outline';
import * as React from 'react';
import { CloseDepositAccountWithSidebar } from '../../routes/account/routes/manage/routes/account-details/components/accounts/components/close-deposit-account/close-deposit-account-with-sidebar';

type CloseDepositAccountBannerProps = {
  title?: string;
  account: { id: string; balanceInCents: number };
};

export function CloseDepositAccountBanner(props: CloseDepositAccountBannerProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  function onDrawerClose() {
    setIsOpen(false);
  }

  function onDrawerOpen() {
    setIsOpen(true);
  }

  function handleInnerDivClick(event: React.MouseEvent) {
    event.stopPropagation();
  }

  return (
    <>
      <CloseDepositAccountWithSidebar isOpen={isOpen} onDrawerClose={onDrawerClose} account={props.account} />
      <div
        onClick={handleInnerDivClick}
        className="flex cursor-default text-base font-medium items-center gap-1 p-2 bg-purple-50 rounded-lg border-1 border-purple-900 text-purple-900 mb-2"
      >
        <InformationCircleIcon className="w-5 min-w-[20px]" />
        <p className="ml-1 mr-2 font-normal">
          {props.title ? props.title : 'This account will close on 12/15/2023.'}{' '}
          <span className="underline cursor-pointer" onClick={onDrawerOpen}>
            Close account
          </span>
        </p>
      </div>
    </>
  );
}
