import { FeatureFlag } from '@/components/feature-flag';
import { CloseDepositAccountBanner } from '@/components/wind-down-banners/close-deposit-account-banner';
import { FEATURE_FLAG_WIND_DOWN, FEATURE_FLAG_WIND_DOWN_BANNERS } from '@/constants/features';
import { NYCB_PLANS_DETAILS_PATH, NYCB_PLANS_PATH, PATH_ACCOUNT_DEPOSIT_DETAILS } from '@/constants/routes';
import { DepositAccount, DepositAccountType, Plan, useListRoutinesQuery } from '@/generated/graphql';
import { fromCents } from '@/helpers/currency';
import { sortPlansByDate } from '@/helpers/plans';
import { routeFor } from '@/helpers/router';
import { CurrencyFormatter, InterestRateNumberFormatter } from '@/i18n/numbers';
import { AvailableBalanceTooltip } from '@/routes/account/components/available-balance-tooltip';
import { useCreatePlanDrawerContext } from '@/routes/account/components/create-plan-drawer/hooks/use-create-plan-drawer-context';
import {
  DepositAccountCardHidePlansIcon,
  DepositAccountCardImage,
  DepositAccountCardInfoIcon,
  DepositAccountCardRoutineIcon,
  DepositAccountCardShowPlansIcon,
  JointAccountCardImage,
} from '@/routes/account/routes/accounts/components/images';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import { useQueryClient } from 'react-query';

function getCompletePercent(plan: Plan) {
  if (plan.balanceInCents === 0) {
    return 0;
  }

  if (!plan.goalAmountInCents) {
    return 100;
  }

  const percent = Math.round(
    (parseFloat(plan.balanceInCents.toString()) / parseFloat(plan.goalAmountInCents.toString())) * 100,
  );

  return percent > 100 ? 100 : percent;
}

function Plans(props: { account: DepositAccount }) {
  const createPlanDrawerContext = useCreatePlanDrawerContext();

  const queryClient = useQueryClient();
  const listRoutinesQuery = useListRoutinesQuery();
  const [isShowingPlans, setIsShowingPlans] = React.useState(false);

  function isPoweredByRoutine(planId: string) {
    return !!(listRoutinesQuery.data?.routines || []).find(
      (routine) =>
        routine.action.__typename == 'PlanTransferAction' &&
        (routine.action.planTransferActionOptions as { toAccountId: string })?.toAccountId === planId,
    );
  }

  function onClickShowHidePlans() {
    setIsShowingPlans(!isShowingPlans);
  }

  function onClickCreatePlan() {
    createPlanDrawerContext.openCreatePlanDrawer({
      accountId: props.account.id,
      withFundPlanSteps: true,
      onSuccessCallback: () => {
        queryClient.invalidateQueries('GetDepositAccounts');
      },
    });
  }

  const fivePlans = props.account.plans.sort(sortPlansByDate).slice(0, 5);
  const otherPlansCount = props.account.plans.length - 5;
  const hasPlans = props.account.plans.length !== 0;

  return (
    <>
      <div className="px-2 w-full my-2">
        <div className="w-full h-px bg-gray-100" />
      </div>
      <div className="hidden sm:flex flex-row items-center justify-between px-2 mt-4">
        <div
          className="flex flex-row items-center justify-center bg-gray-50 rounded-md p-2 min-w-[120px]"
          role="button"
          onClick={onClickShowHidePlans}
        >
          {isShowingPlans ? <DepositAccountCardHidePlansIcon /> : <DepositAccountCardShowPlansIcon />}
          <p className="text-base text-gray-800 ml-1">{isShowingPlans ? 'Hide Plans' : 'Show Plans'}</p>
        </div>
        <div>
          <p className="mb-0.5 text-black text-md text-right">
            {CurrencyFormatter.format(
              parseFloat(fromCents(props.account.depositAccountBalances.totalPlansBalance).toString()),
            )}
          </p>
          <p className="text-sm font-normal text-gray-500 text-right">Plan balance</p>
        </div>
      </div>
      <Link
        href={{
          pathname: routeFor(NYCB_PLANS_PATH, {
            path: {
              depositAccountId: props.account.id,
            },
          }),
        }}
        passHref
      >
        <div className="flex sm:hidden flex-row items-center justify-between p-3 hover:bg-gray-50 rounded-md">
          <p className="text-sm text-black">Plan balance</p>
          <p className="mb-0.5 text-black text-base text-right">
            {CurrencyFormatter.format(
              parseFloat(fromCents(props.account.depositAccountBalances.totalPlansBalance).toString()),
            )}
          </p>
        </div>
      </Link>
      <div
        className={clsx('overflow-hidden hidden sm:block sm:w-full w-0 px-1.5 pb-2 duration-300', {
          'opacity-0 max-h-0': !isShowingPlans,
          'opacity-100 max-h-screen': isShowingPlans,
        })}
      >
        <div className="pt-4">
          {hasPlans ? (
            <>
              {fivePlans.map((plan) => (
                <Link
                  key={plan.id}
                  href={routeFor(NYCB_PLANS_DETAILS_PATH, {
                    path: {
                      depositAccountId: props.account.id,
                      planId: plan.id,
                    },
                  })}
                >
                  <div className="flex flex-row items-center justify-between py-3 px-2 hover:bg-gray-50 duratino-100 rounded-md">
                    <div className="flex flex-row items-center w-[18%] min-w-[64px]">
                      <div className="w-6">{isPoweredByRoutine(plan.id) && <DepositAccountCardRoutineIcon />}</div>
                      <p className="text-base text-gray-950 whitespace-nowrap overflow-hidden text-ellipsis w-full">
                        {plan.title}
                      </p>
                    </div>
                    <div className="mx-3 flex flex-row items-center w-[70%]">
                      <div className="rounded-full w-full h-2.5 overflow-hidden bg-gray-100 relative">
                        <div
                          className="z-10 absolute top-0 left-0 h-full"
                          style={{
                            backgroundColor: plan.color,
                            width: `${getCompletePercent(plan)}%`,
                          }}
                        />
                      </div>
                      <p className="text-gray-500 text-sm font-normal ml-2.5 w-36 whitespace-nowrap">
                        {!!plan.goalAmountInCents ? `${getCompletePercent(plan)}% complete` : 'No goal'}
                      </p>
                    </div>
                    <p className="text-base text-gray-950 w-[12%] text-right whitespace-nowrap text-ellipsis overflow-hidden min-w-[48px]">
                      {CurrencyFormatter.format(parseFloat(fromCents(plan.balanceInCents).toString()))}
                    </p>
                  </div>
                </Link>
              ))}
              {otherPlansCount > 0 && (
                <p className="text-center text-gray-500 text-base font-normal">
                  You have {otherPlansCount} more Plan{otherPlansCount === 1 ? '' : 's'}
                </p>
              )}
            </>
          ) : (
            <div className="mb-1">
              <p className="text-gray-950 text-base mb-2">You have no Plan in this account yet.</p>
              <p className="text-gray-500 text-sm font-normal">
                Start saving for your goals! Create a new Savings Plan.
              </p>
            </div>
          )}
          <div className="flex flex-row justify-end px-1.5 mt-3">
            <FeatureFlag invert flag={FEATURE_FLAG_WIND_DOWN}>
              <div
                onClick={onClickCreatePlan}
                className="border-gray-200 border-2 rounded-lg px-5 py-1.5 text-base truncate mr-2"
              >
                Create Plan
              </div>
            </FeatureFlag>
            {hasPlans && (
              <Link
                href={{
                  pathname: routeFor(NYCB_PLANS_PATH, {
                    path: {
                      depositAccountId: props.account.id,
                    },
                  }),
                }}
                passHref
                className="border-gray-200 border-2 rounded-lg px-5 py-1.5 text-base truncate"
              >
                View all Plans
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export function CardDepositAccount(props: { account: DepositAccount }) {
  return (
    <div className="bg-white rounded-lg p-2">
      <FeatureFlag flag={FEATURE_FLAG_WIND_DOWN_BANNERS}>
        <CloseDepositAccountBanner
          account={{ id: props.account.id, balanceInCents: props.account.depositAccountBalances.balanceInCents }}
        />
      </FeatureFlag>
      {props.account.isPendingJointAccount && (
        <p className="sm:hidden block w-fit py-0.5 px-2 mb-4 text-gray-900 bg-yellow-300 rounded-md text-sm">Pending</p>
      )}
      <Link
        href={{
          pathname: routeFor(PATH_ACCOUNT_DEPOSIT_DETAILS, {
            path: {
              depositAccountId: props.account.id,
            },
          }),
        }}
      >
        <div className="flex flex-row items-center justify-between hover:bg-gray-50 p-4 duration-100 rounded-md">
          <div className="flex flex-row items-center">
            <div className="sm:block hidden mr-5">
              {props.account.accountType === DepositAccountType.Joint || props.account.isPendingJointAccount ? (
                <JointAccountCardImage />
              ) : (
                <DepositAccountCardImage />
              )}
            </div>
            <div className="mr-5">
              <div className="flex flex-row items-center">
                <p className="mb-0.5 text-black text-base sm:text-md sm:leading-6">
                  {props.account.accountType === DepositAccountType.Joint || props.account.isPendingJointAccount
                    ? 'Joint '
                    : ''}{' '}
                  Deposit Account
                </p>
                {props.account.isPendingJointAccount && (
                  <p className="sm:block hidden w-fit py-0.5 px-2 ml-2.5 text-gray-900 bg-yellow-300 rounded-md text-sm">
                    Pending
                  </p>
                )}
              </div>
              <div className="flex flex-row flex-wrap items-center">
                <p className="text-sm font-normal text-gray-500 mr-2 whitespace-nowrap">
                  •••• {props.account.depositAccountNumber.lastFour}
                </p>
                <p className="text-sm font-normal text-gray-500 whitespace-nowrap">
                  {InterestRateNumberFormatter.format(parseFloat(props.account.apy.apyInterestRate))} APY
                  <sup>1</sup>
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="mb-0.5 text-black sm:text-md text-base text-right">
              {CurrencyFormatter.format(
                parseFloat(fromCents(props.account.depositAccountBalances.availableBalanceInCents).toString()),
              )}
            </p>
            <div className="flex flex-row items-center">
              <AvailableBalanceTooltip
                icon={<DepositAccountCardInfoIcon />}
                className="mt-0.5"
                panelClassName="right-0 left-auto -translate-x-0 lg:right-auto lg:left-1/2 lg:-translate-x-1/2"
              />
              <p className="text-sm font-normal text-gray-500 text-right ml-1.5 whitespace-nowrap">Available balance</p>
            </div>
          </div>
        </div>
      </Link>
      <Plans account={props.account} />
    </div>
  );
}
