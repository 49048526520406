import { Controller, ControllerProps } from 'react-hook-form';
import { Dropdown, DropdownProps } from '../dropdown';

type ControlledDropdownProps<T> = Omit<ControllerProps<T>, 'render'> &
  Omit<DropdownProps, 'onChange'> & { onChange?: (state: { value: string | number; label: string }) => void };

const findByValue = (value: string) => (option: { value: string | number; label: string }) => value === option.value;

export function findValue<TProps>(options: { value: TProps; label: string }[], value: TProps) {
  return options.find((c) => c.value == value) || null;
}

export function ControlledDropdown<T>({
  control,
  name,
  defaultValue,
  onChange,
  ...dropdownProps
}: ControlledDropdownProps<T>) {
  const options = dropdownProps?.options as Array<{ value: string | number; label: string }>;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Dropdown
          name={name}
          ref={field.ref}
          onChange={(e) => {
            field.onChange(e.value);
            onChange?.(e);
          }}
          onBlur={field.onBlur}
          value={options.find(findByValue(field.value as string))}
          {...dropdownProps}
        />
      )}
    />
  );
}
