import { InfoButtonToolTip } from '@/components/info-button-tool-tip';
import { URL_HMB_TO_NYCB_DEPOSIT_AGREEMENT } from '@/constants/app';
import Link from 'next/link';
import React from 'react';

export function AvailableBalanceTooltip(props: {
  className?: string;
  panelClassName?: string;
  arrowClassName?: string;
  iconClassName?: string;
  icon?: React.ReactNode;
}) {
  return (
    <InfoButtonToolTip
      className={props.className || 'mt-px'}
      panelClassName={props.panelClassName}
      arrowClassName={props.arrowClassName}
      iconClassName={props.iconClassName}
      icon={props.icon}
    >
      <div className="bg-gray-700 rounded p-4">
        <p className="text-white font-medium text-sm">
          Available balance does not include balances in any Plans; see the definition of “Available balance” in the{' '}
          <Link href={URL_HMB_TO_NYCB_DEPOSIT_AGREEMENT} className="underline" target="_blank" rel="noreferrer">
            HMBradley Deposit Account Agreement and Disclosures
          </Link>
          .
        </p>
      </div>
    </InfoButtonToolTip>
  );
}
