import { ComplianceConsent } from '@/types/mainframe';
import { DateTime } from 'luxon';

/** @public */
export function isConsentRevoked(consent: ComplianceConsent) {
  return consent.revokedAt != null;
}

export function isOptedIn(consent?: ComplianceConsent) {
  if (!consent) {
    return false;
  }
  return consent.isMostRecent && !consent.shouldOptIn;
}

export function isConsentRenewable(consent: ComplianceConsent) {
  if (!consent.renewalDate) {
    return false;
  }

  return DateTime.now() >= DateTime.fromISO(consent.renewalDate);
}

export function isActivelyRevoked(consent: ComplianceConsent) {
  return isConsentRenewable(consent) && isConsentRevoked(consent);
}

export function isMostRecentConsent(consent: ComplianceConsent) {
  return consent.isMostRecent;
}

export function hasEverOptedIn(consents: ComplianceConsent[]) {
  return consents.length !== 0;
}
